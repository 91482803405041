import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Tippy from "@tippyjs/react";
import { NetworkIcon, QuestionIcon } from "../../../constant/Svg";
import { AuthContext } from "../../../context/AuthContext";
import { useApi } from "../../../api/api";
import { SubscriptionData } from "../Purchase";
import prettyBytes from "pretty-bytes";
import Logo from "../../../assets/logo.png";

interface NavLink {
  name: string;
  route: string;
}

interface UsageSectionProps {
  title: string;
  data: SubscriptionData | null;
}

interface NavigationLinkProps {
  route: string;
  name: string;
  currentPath: string;
}

const LINKS: NavLink[] = [
  {
    name: "Your Proxies",
    route: "/dashboard",
  },
  {
    name: "Activities",
    route: "/activities",
  },
  {
    name: "Purchase",
    route: "/purchase",
  },
];

const UsageSection: React.FC<UsageSectionProps> = ({ data, title }) => {
  const usageReadable = prettyBytes(data?.usage || 0, {
    maximumFractionDigits: 2,
  });

  return (
    <div className="bg-[#292826] rounded-[0.1875rem]">
      <h3 className="text-[0.5rem] min-h-5 items-center px-6 flex bg-[#323131] rounded-t-[3px]">
        {title}
      </h3>
      <div className="px-6">
        <p className="text-white text-xs font-bold pt-[0.375rem]">
          {usageReadable} -{" "}
          {data?.usageOption === "unlimited" ? "Unlimited GB" : "5,000 GB"}
        </p>
        <p className="text-[0.6875rem] text-[rgba(255,255,255,0.50)] mt-1 pb-[0.625rem]">
          On the left is total usage and on the right side your total approved.
        </p>
      </div>
    </div>
  );
};

const NavigationLink: React.FC<NavigationLinkProps> = ({
  route,
  name,
  currentPath,
}) => (
  <Link
    className={`text-xs transition-all ${
      currentPath === route ? "text-white" : "text-[#ccc] hover:text-white"
    }`}
    to={route}
  >
    {name}
  </Link>
);

function UsagePopup({
  subscriptionData,
}: {
  subscriptionData: SubscriptionData | null;
}) {
  return (
    <>
      {/* Inline style for the tooltip */}
      <style>
        {`
        .custom-tooltip {
          max-width: 516px !important;
          width: 100%;
          margin-top: 10px;
        }
      `}
      </style>

      <div className="p-6 text-white bg-[#212121] rounded-lg w-full max-w-[516px] border border-[rgba(255,255,255,0.05)]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-[0.875rem] flex items-center gap-2 font-bold tracking-[0.15px]">
            Your total consumption
            <Tippy
              content={
                <div className="bg-[#272626] text-xs px-2 py-1 rounded">
                  Show Info Here
                </div>
              }
              animation="shift-away"
              placement="top"
              arrow={false}
            >
              <div className="flex items-center cursor-pointer">
                <QuestionIcon />
              </div>
            </Tippy>
          </h2>
        </div>

        <div className="space-y-6">
          <UsageSection title="Datacenter Proxies" data={subscriptionData} />
        </div>
      </div>
    </>
  );
}

const Navbar: React.FC = () => {
  const location = useLocation();
  const { logout } = useContext(AuthContext);

  const [subscriptionData, setSubscriptionData] =
    useState<SubscriptionData | null>();
  const api = useApi();
  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const response = await api.get("/subscription");
        // console.log(response.data);

        const data: SubscriptionData = response.data;

        setSubscriptionData(data); // Set the subscription data
      } catch (error) {
        console.error("Failed to fetch subscription data:", error);
        setSubscriptionData(null);
      }
    };

    fetchSubscriptionData();
  }, []);

  return (
    <div className="min-h-[3.125rem] border-b border-[#292826] bg-[#1C1B1B] flex items-center max-sm:justify-between pl-[6.25rem] max-2xl:pl-[3.25rem] max-sm:pr-4  max-sm:pl-[1.875rem]">
      <div>
        <img className="w-[20px] h-[20px]" src={Logo} alt="logo" />
      </div>
      <div className="w-[0.0625rem] h-[1.5625rem] bg-[rgba(255,255,255,0.08)] ml-4 mr-3 max-sm:hidden" />
      <div className="flex items-center gap-5">
        {LINKS.map((link) => (
          <NavigationLink
            key={link.route}
            route={link.route}
            name={link.name}
            currentPath={location.pathname}
          />
        ))}
      </div>
      {!!subscriptionData && (
        <Tippy
          content={<UsagePopup subscriptionData={subscriptionData} />}
          interactive={true}
          placement="right"
          arrow={false}
          delay={[100, 0]}
          offset={[0, 10]}
          className="custom-tooltip "
        >
          <button
            className="text-[0.5rem] py-1 px-2 max-sm:hidden rounded-[0.125rem] flex items-center justify-center ml-6 text-black gap-1 transition-all bg-white hover:bg-[#e0e0e0]"
            type="button"
          >
            View usage
            <NetworkIcon />
          </button>
        </Tippy>
      )}

      <button
        className="text-[0.5rem] py-1 px-2 max-sm:hidden rounded-[0.125rem] flex items-center justify-center ml-auto mr-[1.25rem] text-black gap-1 transition-all bg-white hover:bg-[#e0e0e0]"
        type="button"
        onClick={logout}
      >
        Logout
      </button>
    </div>
  );
};

export default Navbar;
