import AuthHeader from "./components/AuthHeader";
import Button from "../../components/Button";
import AuthPage from "./components/AuthPage";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();

  return (
    <div>
      <AuthHeader>
        <Button
          title="Sign Up"
          onClick={() => navigate("/signup")}
          name="sign-up"
        />
      </AuthHeader>
      <div className="mt-10">
        <AuthPage isLogin />
      </div>
      <div className="text-base font-bold text-center mt-[0.375rem] sm:hidden">
        Not have an account?{" "}
        <span
          className="text-[#49C0F8] cursor-pointer"
          onClick={() => navigate("/signup")}
        >
          Sign up
        </span>
      </div>
    </div>
  );
}
