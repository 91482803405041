import React, { useRef, useEffect, useState } from "react";
import Tippy from "@tippyjs/react";
import { DownArrow } from "../../../constant/Svg";
import { ProxyType } from "../../../types/proxy";

interface ProxyTypeOption {
  id: ProxyType;
  label: string;
}

interface ResidentialDropdownProps {
  proxyTypeOptions: ProxyTypeOption[];
  activeTab: string;
  setActiveTab: (id: ProxyType) => void;
}

const ResidentialContent: React.FC<{
  options: ProxyTypeOption[];
  activeTab: string;
  onSelect: (id: string) => void;
  width: number;
}> = React.memo(({ options, activeTab, onSelect, width }) => (
  <div
    className="bg-[#323131] rounded-[0.1875rem] shadow-lg overflow-hidden py-1"
    style={{ width }}
  >
    {options.map((option) => (
      <button
        key={option.id}
        onClick={() => onSelect(option.id)}
        className={`w-full px-4 py-2 text-left transition-colors text-xs text-white hover:bg-[#272626] ${
          option.id === activeTab ? "bg-[#272626]" : ""
        }`}
      >
        {option.label}
      </button>
    ))}
  </div>
));

const ProxyTypeDropdown: React.FC<ResidentialDropdownProps> = ({
  proxyTypeOptions,
  activeTab,
  setActiveTab,
}) => {
  const [width, setWidth] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const updateWidth = (): void => {
      setWidth(buttonRef.current?.offsetWidth || 0);
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  return (
    <Tippy
      content={
        <ResidentialContent
          options={proxyTypeOptions}
          activeTab={activeTab}
          onSelect={(id) => {
            setActiveTab(id as ProxyType);
            setIsOpen(false); // Close the dropdown on selection
          }}
          width={width}
        />
      }
      interactive={true}
      placement="bottom-start"
      visible={isOpen}
      onClickOutside={() => setIsOpen(false)}
      animation="shift-away"
      theme="custom"
      arrow={false}
      appendTo={() => document.body}
      maxWidth="none"
    >
      <button
        ref={buttonRef}
        onClick={() => setIsOpen((prev) => !prev)}
        className="w-full flex items-center justify-between px-4 min-h-[2.1875rem] bg-[#191719] hover:bg-[#2A2A2A] rounded-[0.1875rem] text-white transition-colors"
        aria-haspopup="listbox"
        aria-expanded={isOpen}
      >
        <span className="text-xs mr-4 tracking-[0.0094rem] whitespace-nowrap">
          {
            // Display the label of the active option
            proxyTypeOptions.find((option) => option.id === activeTab)?.label ||
              "Residential"
          }
        </span>
        <DownArrow />
      </button>
    </Tippy>
  );
};

export default ProxyTypeDropdown;
