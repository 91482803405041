import { WarningIcon } from "../../../constant/Svg";

export default function WarningOverlay() {
  return (
    <div className="p-3 flex gap-2 items-center rounded-lg bg-[rgba(255,180,0,0.15)] border border-[rgba(255,180,0,0.10)] mb-4">
      <WarningIcon />
      <div className="text-[0.6875rem]">
        You are currently downgrading. Your new scaled-down plan will be
        effective <span className="font-bold">on your next renewal date.</span>
      </div>
    </div>
  );
}
