import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/auth/Login";
import Signup from "./pages/auth/SignUp";
import Dashboard from "./pages/screens/Dashboard";
import { Toaster } from "sonner";
import Purchase from "./pages/screens/Purchase";
import Activities from "./pages/screens/Activities";
import { AuthProvider } from "./context/AuthContext";
import OAuthCallback from "./pages/auth/OAuthCallback";

function App() {
  return (
    <AuthProvider>
      <div className="min-h-screen">
        <Toaster position="top-right" theme="dark" />
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/oauth/callback" element={<OAuthCallback />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/purchase" element={<Purchase />} />
          <Route path="/activities" element={<Activities />} />

          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
