const Spinner = ({ size = 20, color = "black" }) => {
  return (
    <svg
      className="animate-spin"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        r="10"
        stroke={color}
        strokeWidth="2.5"
        opacity="0.25"
      />
      <path
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        d="M12 2C6.477 2 2 6.477 2 12"
      />
    </svg>
  );
};

export default Spinner;
