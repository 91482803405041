export enum ProxyType {
  RESIDENTIAL = "residential",
  DATACENTER = "datacenter",
}

export enum ProxyStatusType {
  WARNING = "warning",
  SUCCESS = "success",
  ERROR = "error",
}

export interface Proxy {
  _id: string;
  proxyAddress: string;
  port: number;
  username: string;
  password: string;
  status: {
    current: number;
    total: number;
    type: ProxyStatusType;
    details: {
      attention: {
        type: string;
        fixedUpstream: boolean;
        transferable: boolean;
        upToThreeHolds: boolean;
        inflowStream: boolean;
      };
      contract: {
        address: string;
        mode: string;
      };
      creator: {
        address: string;
        stream: string;
      };
      testerUrl: string;
    };
  };
  country: string;
  lastChecked?: string;
  type: ProxyType;
}
