import { RightArrow } from "../../../constant/Svg";

interface RegionData {
  name: string;
  type: string;
  totalIps: number;
  totalIpsUsed: number;
  availability: number;
}

interface RegionSelectorProps {
  regions: RegionData[];
  regionInputValues: Record<string, string>;
  regionErrors: Record<string, string>;
  regionGlobalError: string;
  focusedRegion: string | null;
  setFocusedRegion: React.Dispatch<React.SetStateAction<string | null>>;
  handleAvailabilityChange: (region: RegionData, value: string) => void;
}

const RegionSelector = ({
  regions,
  regionInputValues,
  regionErrors,
  regionGlobalError,
  focusedRegion,
  setFocusedRegion,
  handleAvailabilityChange,
}: RegionSelectorProps) => {
  return (
    <div className="mt-[2.375rem]">
      <h2 className="text-white text-xs font-medium font-[Roboto] mb-[0.625rem]">
        Choose Region
      </h2>
      {regionGlobalError && (
        <div className="mb-3 text-[0.625rem] text-red-500">
          {regionGlobalError}
        </div>
      )}
      <div className="flex flex-wrap gap-3 max-sm:flex-col max-sm:items-stretch">
        {regions.map((region) => (
          <div key={region.name} className="flex flex-col max-sm:max-w-full">
            <div
              className={`w-full flex min-h-[2.1875rem] min-w-[9.375rem] px-4 py-[0.25rem] border-b-[0.0938rem] text-left items-center border-[#1F1E1E] bg-[#272626] rounded-t-[0.1875rem] transition-all text-white text-xs ${
                focusedRegion === region.name
                  ? "!text-[#49C0F8] !bg-[rgba(73,192,248,0.2)] border-transparent"
                  : "bg-[#272626]"
              }`}
            >
              {region.name} ({region.availability})
            </div>
            <div className="flex flex-col">
              <div className="px-4 text-xs w-full min-h-[2.1875rem] bg-[#272626] border-none text-white flex items-center justify-between rounded-b-[0.1875rem] ">
                <input
                  type="text"
                  placeholder="0"
                  value={regionInputValues[region.name] || ""}
                  onChange={(e) =>
                    handleAvailabilityChange(region, e.target.value)
                  }
                  onFocus={() => setFocusedRegion(region.name)}
                  onBlur={() => setFocusedRegion(null)}
                  className="w-full bg-transparent text-white placeholder:text-gray-500 text-xs outline-none block h-full"
                />
              </div>
              {regionErrors[region.name] && (
                <div className="px-4 py-1 text-[0.625rem] text-red-500 bg-[#272626] rounded-b-[0.1875rem]">
                  {regionErrors[region.name]}
                </div>
              )}
            </div>
          </div>
        ))}

        <div className="flex items-center gap-2 max-sm:hidden">
          <RightArrow />
          <div>
            <div className="text-white text-[0.625rem]">Soon more :)</div>
            <div className="text-[0.625rem]">
              Stay updated on{" "}
              <a
                href="#"
                className="text-[rgba(255,255,255,.5)] relative after:absolute after:left-0 after:bottom-0 after:w-full after:h-px after:bg-[rgba(255,255,255,.5)] hover:after:bg-white hover:text-white"
              >
                Discord
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegionSelector;
