import React, { useCallback, useEffect, useState } from "react";
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";
import { PathIcon } from "../../../constant/Svg";
import InfiniteScroll from "../../../components/InfiniteScroll";
import { ProxyType } from "../../../types/proxy";
import { useApi } from "../../../api/api";
import { Activity } from "../../../types/activity";
import debounce from "lodash.debounce";
import axiosErrorHandler from "../../../utils/axiosErrorHandler";
import { parseISO, format } from "date-fns";
import prettyBytes from "pretty-bytes";

const columns: ColumnDef<any, any>[] = [
  {
    accessorKey: "time",
    header: "Time",
    cell: (info) => {
      const row = info.row.original;
      const isError = row.isError;

      // Get the date string from the data
      const dateString = info.getValue();

      // Parse and format the date
      let formattedDate = "";
      if (dateString) {
        const date = parseISO(dateString);
        formattedDate = format(date, "MMM dd.HH:mm.ss.SS").toUpperCase();
      }

      return (
        <div
          className={`py-4 font-mono ${
            isError ? "text-[#FF565F]" : "text-white"
          }`}
        >
          {formattedDate}
        </div>
      );
    },
  },
  {
    accessorKey: "method",
    header: "Method",
    cell: (info) => {
      const row = info.row.original;
      const isError = row.isError;
      return (
        <div className="py-4">
          <span
            className={`py-1 rounded text-[0.6875rem] flex items-center gap-[0.375rem] ${
              isError ? "text-[#E2162A]" : "text-[#65FC9F]"
            }`}
          >
            <span
              className={`p-1 inline-flex items-center justify-center text-center ${
                !isError
                  ? "text-[rgba(255,255,255,.5)] border-[rgba(255,255,255,.5)]"
                  : "text-[#FF565F] border-[#FF565F]"
              } h-[1.25rem] min-w-9 rounded border`}
            >
              {info.getValue()}
            </span>{" "}
            <span className="uppercase">{row.status}</span>
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "proxy",
    header: "Proxy",
    cell: (info) => {
      const row = info.row.original;
      const isError = row.isError;
      return (
        <div className={`py-4 ${isError ? "text-[#FF565F]" : "text-white"}`}>
          {info.getValue()}
        </div>
      );
    },
  },
  {
    accessorKey: "path",
    header: "Path",
    cell: (info) => {
      const row = info.row.original;
      const isError = row.isError;
      return (
        <div className={`py-4 ${isError ? "text-[#FF565F]" : "text-white"}`}>
          <span className="inline-flex items-center gap-3">
            <PathIcon fill={isError ? "#FF565F" : "rgba(255,255,255,.5)"} />
            {info.getValue()}
          </span>
        </div>
      );
    },
  },
  {
    accessorKey: "dataConsumed",
    header: "Data consumed",
    cell: (info) => {
      const row = info.row.original;
      const isError = row.isError;
      const usageReadable = prettyBytes(info.getValue() || 0, {
        maximumFractionDigits: 2,
      });
      return (
        <div
          className={`py-4 font-mono ${
            isError ? "text-[#FF565F]" : "text-white"
          }`}
        >
          {usageReadable}
        </div>
      );
    },
  },
];

interface NetworkTrafficTableProps {
  type: ProxyType;
  timeRange: string;
  refresh: number;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export default function NetworkTrafficTable({
  type,
  timeRange,
  refresh,
  isLoading,
  setIsLoading,
}: NetworkTrafficTableProps) {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const api = useApi();
  const LIMIT = 10;

  const loadActivities = useCallback(async () => {
    if (isLoading) return;

    setIsLoading(true);
    try {
      const response = await api.get("/activities", {
        params: {
          page,
          limit: LIMIT,
          type,
          timeRange,
        },
      });
      if (!response.data || !response.data.activities) return;

      const { activities: newActivities, total } = response.data;
      if (newActivities.length) {
        setActivities((prev) => {
          const newPageActivities = newActivities.filter(
            (proxy: Activity) => !prev.some((r) => r._id === proxy._id)
          );
          const updatedProxies = [...prev, ...newPageActivities];

          setHasMore(updatedProxies.length < total);
          setPage((prevPage) => prevPage + 1);

          return updatedProxies;
        });
      } else {
        setHasMore(false);
      }
    } catch (error) {
      axiosErrorHandler(error);
      setHasMore(false);
    } finally {
      setIsLoading(false);
    }
  }, [page, type, timeRange]);

  const debouncedLoadMore = useCallback(debounce(loadActivities, 100), [
    loadActivities,
  ]);

  const resetData = useCallback(() => {
    setActivities([]);
    setHasMore(true);
    setPage(1);
  }, []);

  // Reset when filters change or refresh is clicked
  useEffect(() => {
    resetData();
  }, [type, timeRange, refresh]);

  useEffect(() => {
    if (activities.length === 0 && hasMore && !isLoading) {
      debouncedLoadMore();
    }
  }, [debouncedLoadMore, activities.length, hasMore, isLoading, refresh]);

  const table = useReactTable({
    data: activities || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full overflow-x-auto text-[0.8125rem] leading-none noscroll">
      <InfiniteScroll
        loadMore={debouncedLoadMore}
        hasMore={hasMore}
        loader={
          <div className="text-center py-4 text-gray-400">
            Loading more activities...
          </div>
        }
      >
        <table className="w-full border-separate border-spacing-0">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="border-b border-[#333333]">
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`p-0 min-w-[100px] text-left font-normal border-b border-[#333333] ${
                      header.id === "time"
                        ? "pl-[8rem] max-2xl:pl-[3.25rem] max-sm:pl-[1.875rem]"
                        : "pl-6"
                    } ${
                      header.id === "dataConsumed" ? "pr-16 max-sm:pr-6" : ""
                    }`}
                  >
                    <div className="py-4">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="relative">
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => {
                const isError = row.original.isError;
                const rowClass = isError
                  ? "bg-[#330A11]"
                  : "hover:bg-[#1A1A1A]";
                return (
                  <tr key={row.id} className={`${rowClass}`}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={`p-0 border-b border-[#333333] min-w-[100px] whitespace-nowrap ${
                          cell.column.id === "time"
                            ? "pl-[8rem] max-2xl:pl-[3.25rem] max-sm:pl-[1.875rem]"
                            : "pl-6"
                        } ${
                          cell.column.id === "dataConsumed"
                            ? "pr-16 max-sm:pr-6"
                            : ""
                        }`}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <>
                <tr>
                  <td className="whitespace-nowrap py-4 pl-[8rem] max-2xl:pl-[3.25rem] max-sm:pl-[1.875rem] text-white">
                    SEP 03.12:38.15.42
                  </td>
                  <td className="whitespace-nowrap py-4 pl-6 text-white">
                    200
                  </td>
                  <td className="whitespace-nowrap py-4 pl-6 text-white">
                    network.roundproxies.com:435:username:password
                  </td>
                  <td className="whitespace-nowrap py-4 pl-6 text-white">
                    /api/analyze-test
                  </td>
                  <td className="whitespace-nowrap py-4 pl-6 pr-16 max-sm:pr-6 text-white">
                    {prettyBytes(200000, { maximumFractionDigits: 2 })}
                  </td>
                </tr>
                <div
                  style={{
                    background: `linear-gradient(180deg, rgba(31, 30, 30, 0.00) -224.26%, #1F1E1E 42.84%)`,
                  }}
                  className="h-[8.4375rem] absolute inset-0"
                />
              </>
            )}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
}
