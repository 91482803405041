import React from "react";

export default function Footer() {
  return (
    <div className="min-h-[3.125rem] border-t border-[#292826] bg-[#1C1B1B] flex items-center pl-[6.25rem] pr-[2.625rem] justify-between max-2xl:pl-[3.25rem] max-sm:px-[1.875rem]">
      <div className="text-[0.625rem] font-medium">
        Roundproxies:{" "}
        <span className="opacity-50 font-normal font-[Berkeley]">
          0.0.1 BETA
        </span>
      </div>
      <div className="text-[0.625rem] font-medium max-sm:hidden">
        <span className="text-[#00D885]">Systems operational</span>{" "}
        <span className="opacity-50">|</span>{" "}
        <a
        // href="#" target="_blank"
        >
          Guide Hub
        </a>
      </div>
    </div>
  );
}
