import React from "react";

export default function CustomButton({
  title,
  onClick,
  classes,
  name,
  type,
  disabled = false,
}: {
  title: React.ReactNode;
  name: string;
  classes?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}) {
  return (
    <button
      aria-label={name}
      name={name}
      type={type}
      disabled={disabled}
      className={`min-h-[2.8125rem] relative tracking-wider text-[#131F24] text-sm uppercase font-bold rounded-[0.3125rem] leading-[0.875rem] bg-[#1899D6] 
        transition-all duration-200 ease-in-out
        hover:bg-opacity-20 hover:shadow-sm
        group
        ${classes}`}
      onClick={onClick}
    >
      <span
        className="absolute inset-0 bottom-1 bg-[#49C0F8] rounded-[0.3125rem] transition-all duration-200 ease-in-out 
        group-hover:bottom-0 group-hover:shadow-[0_0.25rem_0.9375rem_rgba(72,192,248,0.35)]"
      />
      <span className="relative z-10">{title}</span>
    </button>
  );
}
