import { RadioOption } from "../../../components/RadioOption";
import Tippy from "@tippyjs/react";
import { Question } from "../../../constant/Svg";
import { useEffect, useState } from "react";
import { useApi } from "../../../api/api";
import { UsageOption } from "../../../types/purchase";

export const UsageOptions = ({
  pricing,
  setPricing,
  usageOption,
  setUsageOption,
}: {
  pricing: number;
  setPricing: React.Dispatch<React.SetStateAction<number>>;
  usageOption: UsageOption;
  setUsageOption: React.Dispatch<React.SetStateAction<UsageOption>>;
}) => {
  const api = useApi();

  useEffect(() => {
    const fetchPricing = async () => {
      try {
        const response = await api.get("/payment/pricing/unlimited-usage");
        const data = response.data;

        setPricing(data?.unit_amount);
      } catch (error) {
        console.error("Failed to fetch pricing data:", error);
      }
    };

    fetchPricing();
  }, [api]);

  const formattedPrice = (pricing / 100).toFixed(2);

  return (
    <div className="mt-[2.375rem]">
      <div className="flex items-center gap-2 mb-[0.625rem]">
        <h2 className="text-white text-xs font-medium font-[Roboto]">
          Usage Option
        </h2>
        <button
          className="group"
          onClick={() =>
            window.open(
              "https://docs.roundproxies.com/en/articles/10214823-usage-option",
              "_blank"
            )
          }
        >
          <Question />
        </button>
      </div>
      <div className="space-y-3">
        <RadioOption
          selected={usageOption === UsageOption.LIMITED}
          label="5,000GB Data"
          description="For medium applications, use up to 5,000GB in data"
          rightLabel="FREE /mo"
          rightDescription="By default this is included"
          onClick={() => {
            setUsageOption(UsageOption.LIMITED);
          }}
        />
        <RadioOption
          selected={usageOption === UsageOption.UNLIMITED}
          label="Unlimited Data"
          description="No data limitation, set our servers on fire"
          rightLabel={`${formattedPrice} /mo`}
          rightDescription="Unleash unlimited bandwidth"
          onClick={() => setUsageOption(UsageOption.UNLIMITED)}
        />
      </div>
    </div>
  );
};
