import { Proxy } from "../../../types/proxy";
import Tippy from "@tippyjs/react";
import {
  Arrow,
  Check,
  ShieldAlert,
  ShieldCheck,
  ShieldOutline,
} from "../../../constant/Svg";

const StatusBadge = ({ status }: { status: Proxy["status"] }) => {
  const formatLongText = (text: string): string => {
    if (!text || text.length <= 8) return text;
    const first4 = text.slice(0, 4);
    const last4 = text.slice(-4);
    return `${first4}...${last4}`;
  };

  const icons = {
    warning: <ShieldAlert className="w-5 h-5 scale-95 text-yellow-500" />,
    success: <ShieldCheck className="w-5 h-5 text-green-500" />,
    error: <ShieldAlert className="w-5 h-5 text-red-500" />,
  };

  const getIcon = (isShieldIcon: boolean) => {
    return isShieldIcon ? <ShieldOutline /> : <Check />;
  };

  // Convert boolean fields to items for display
  const statusItems = [
    {
      label: "Fixed Upstream",
      checked: status.details.attention.fixedUpstream,
    },
    {
      label: "Transferable",
      checked: status.details.attention.transferable,
    },
    {
      label: "Up To 3 Holds",
      checked: status.details.attention.upToThreeHolds,
    },
    {
      label: "Inflow Stream IFOOE",
      checked: status.details.attention.inflowStream,
    },
  ];

  const TooltipContent = () => (
    <div className="p-3 bg-[#212121] w-full max-w-[32.25rem] max-sm:max-w-[21.25rem]">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="bg-[#292826] rounded px-3 py-2">
          <div className="font-medium text-[0.5625rem] text-[#FFC800] bg-[rgba(254,198,0,0.20)] max-w-[7.5rem] rounded text-center mb-1 px-1 py-[0.1875rem]">
            {status.details.attention.type}
          </div>

          <div>
            {statusItems.map((item, index) => (
              <div
                key={index}
                className="flex items-center justify-between gap-2 min-h-6 text-xs opacity-70 underline rounded transition-colors"
              >
                <span>{item.label}</span>
                {getIcon(!item.checked)}
              </div>
            ))}
          </div>
        </div>

        <div className="flex flex-col gap-2 text-xs">
          <div className="flex flex-col justify-between bg-[#292826] rounded px-3 py-2">
            <div className="flex items-center justify-between min-h-6">
              <div className="text-xs">CONTRACT</div>
              <div className="flex items-center gap-2">
                <span className="opacity-70">
                  {formatLongText(status.details.contract.address)}
                </span>
                <Arrow />
              </div>
            </div>
            <div className="flex items-center justify-between min-h-6">
              <div className="text-xs opacity-70">Mode</div>
              <div className="opacity-70">{status.details.contract.mode}</div>
            </div>
          </div>

          <div className="flex flex-col justify-between bg-[#292826] rounded px-3 py-2">
            <div className="flex items-center justify-between min-h-6">
              <div className="text-xs">CREATOR</div>
              <div className="flex items-center gap-2">
                <span className="opacity-70">
                  {formatLongText(status.details.creator.address)}
                </span>
                <Arrow />
              </div>
            </div>
            <div className="flex items-center justify-between min-h-6">
              <div className="text-xs opacity-70">Stream</div>
              <div className="opacity-70">{status.details.creator.stream}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2 p-3 bg-[#292826] rounded">
        <p className="text-center text-sm text-[#707070] max-w-[80%] m-auto">
          Please make sure that you are testing the proxy the right way{" "}
          <a
            href={status.details.testerUrl}
            className="text-white hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            theproxytester.com (FREE)
          </a>{" "}
          and report.
        </p>

        <button
          className="w-full text-left py-2 px-4 min-h-[2.375rem] text-black rounded mt-4 text-sm font-medium
            bg-white hover:bg-[#e0e0e0] active:bg-[#c7c7c7] transition-colors"
          onClick={() => {
            // Handle report functionality
            console.log("Report IP:", status);
          }}
        >
          Report IP Address
        </button>
      </div>
    </div>
  );

  return (
    // <Tippy
    //   content={<TooltipContent />}
    //   interactive={true}
    //   placement="bottom-start"
    //   animation="shift-away"
    //   arrow={false}
    //   duration={[200, 150]}
    //   delay={[200, 0]}
    //   appendTo={() => document.body}
    //   offset={[0, 12]}
    //   maxWidth={550}
    //   className="bg-[#212121] rounded-[0.625rem] shadow-xl border border-[rgba(255,255,255,.05)] [&>div]:bg-transparent"
    // >
    //   <div className="flex items-center gap-2 cursor-pointer">
    //     {icons[status.type as keyof typeof icons]}
    //     <span>
    //       {status.current}{" "}
    //       <span className="text-[#717A7A]">/ {status.total}</span>
    //     </span>
    //   </div>
    // </Tippy>
    <div className="flex items-center gap-2">
      {icons[status.type as keyof typeof icons]}
      <span>
        {status.current}{" "}
        <span className="text-[#717A7A]">/ {status.total}</span>
      </span>
    </div>
  );
};

export default StatusBadge;
