import React from "react";

export default function Button({
  title,
  onClick,
  classes,
  name,
  type,
  disabled = false,
}: {
  title: React.ReactNode;
  name: string;
  classes?: string;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
}) {
  return (
    <button
      aria-label={name}
      name={name}
      type={type}
      disabled={disabled}
      style={{
        border: `0.0625rem solid rgba(255, 255, 255, 0.02)`,
      }}
      className={`min-h-10 min-w-[5.3125rem] text-xs bg-opacity-10 rounded-[0.3125rem] font-medium leading-[0.875rem] bg-[#4D4D4D] 
        transition-all duration-200 ease-in-out
        hover:bg-opacity-20 hover:shadow-lg
        ${classes}`}
      onClick={onClick}
    >
      {title}
    </button>
  );
}
