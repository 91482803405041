import Button from "../../../components/Button";
import { Toaster, toast } from "sonner";
import { ProxyType } from "../../../types/proxy";

interface ProxyTabsProps {
  onDownloadAll: () => Promise<void>;
  onCopyAll: () => Promise<void>;
  isLoading: boolean;
}

export default function ProxyTabs({
  onDownloadAll,
  onCopyAll,
  isLoading,
}: ProxyTabsProps) {
  const actions = [
    {
      id: "download",
      name: "Download all",
      onClick: () => {
        if (isLoading) return;
        toast.promise(onDownloadAll, {
          loading: "Downloading proxies...",
          success: "All proxies downloaded successfully",
          error: "Failed to download proxies",
        });
      },
    },
    {
      id: "copy",
      name: "Copy all",
      onClick: () => {
        if (isLoading) return;
        toast.promise(onCopyAll, {
          loading: "Copying to clipboard...",
          success: "All proxies copied to clipboard",
          error: "Failed to copy proxies",
        });
      },
    },
  ];

  return (
    <>
      <Toaster position="top-right" expand={false} richColors theme="dark" />

      <div className="flex min-w-0 min-h-[4.8125rem] overflow-x-auto noscroll">
        <div className="pl-[6.25rem] py-[1.5625rem] flex items-center max-2xl:pl-[3.25rem] max-sm:pl-[1.875rem]">
          <div className="flex items-center gap-3">
            {actions.map((action) => (
              <Button
                name={action.name}
                key={action.id}
                onClick={action.onClick}
                title={action.name}
                disabled={isLoading}
                classes="!px-4 !py-[0.625rem] !leading-none hover:!bg-[#2A2A2A] text-nowrap !min-h-[2.1875rem] !text-xs rounded-[0.3125rem] transition-colors !border-none !bg-[#191719]"
              />
            ))}
          </div>

          <div className="text-[0.625rem] leading-4 text-white ml-4 text-nowrap max-sm:pr-6">
            Manage everything at once,
            <br />
            simply start, pause or delete all of your homework.
          </div>
        </div>
      </div>
    </>
  );
}
