import { useState } from "react";
import ActivityTabs from "./components/ActivityTabs";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import NetworkTrafficTable from "./components/NetworkTrafficTable";
import { ProxyType } from "../../types/proxy";

export default function Activities() {
  const [type, setType] = useState<ProxyType>(ProxyType.DATACENTER);
  const [timeRange, setTimeRange] = useState("last30");
  const [refreshKey, setRefreshKey] = useState(0); // To trigger refresh
  const [loading, setLoading] = useState(false);

  const handleUpdate = () => {
    setRefreshKey((prev) => prev + 1);
  };

  return (
    <div
      className="h-screen grid grid-rows-[auto_1fr_auto]"
      style={{
        background: `linear-gradient(0deg, rgba(28, 27, 27, 0.50) 0%, rgba(28, 27, 27, 0.50) 100%), #212121`,
      }}
    >
      <Navbar />
      <div className="flex flex-col min-h-0 overflow-auto">
        <ActivityTabs
          type={type}
          onTypeChange={setType}
          timeRange={timeRange}
          onTimeRangeChange={setTimeRange}
          onUpdate={handleUpdate}
          loading={loading}
        />
        <NetworkTrafficTable
          type={type}
          timeRange={timeRange}
          refresh={refreshKey}
          isLoading={loading}
          setIsLoading={setLoading}
        />
      </div>
      <Footer />
    </div>
  );
}
