import { useCallback, useEffect, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import { Proxy, ProxyType } from "../../../types/proxy";
import { useApi } from "../../../api/api";
import InfiniteScroll from "../../../components/InfiniteScroll";
import StatusBadge from "./StatusBadge";
import debounce from "lodash.debounce";
import axiosErrorHandler from "../../../utils/axiosErrorHandler";

const columnHelper = createColumnHelper<Proxy>();

const columns = [
  columnHelper.accessor("proxyAddress", {
    header: "Proxy address",
    cell: (info) => (
      <div className="pl-[8rem] max-2xl:pl-[3.25rem] max-sm:pl-[1.875rem]">
        {info.getValue()}
      </div>
    ),
  }),
  columnHelper.accessor("port", {
    header: "Port",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("username", {
    header: "Username",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("password", {
    header: "Password",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (info) => <StatusBadge status={info.getValue()} />,
  }),
  columnHelper.accessor("country", {
    header: "Country",
    cell: (info) => (
      <div className="pr-[3rem] max-2xl:pr-[3.25rem]  max-sm:pr-[1.875rem]">
        {info.getValue()}
      </div>
    ),
  }),
];

interface ProxyTableProps {
  proxies: Proxy[];
}

export default function ProxyTable({ proxies }: ProxyTableProps) {
  const table = useReactTable({
    data: proxies,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="overflow-x-auto noscroll">
      <table className="w-full">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className="border-b border-[#333333]">
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={`
                  py-4 text-left text-xs font-medium text-white
                  ${
                    header.id.includes("proxyAddress")
                      ? "min-w-[150px] pl-[8rem] max-2xl:pl-[3.25rem] max-sm:pl-[1.875rem]"
                      : ""
                  }
                  ${header.id.includes("port") ? "min-w-[80px]" : ""}
                  ${header.id.includes("username") ? "min-w-[160px]" : ""}
                  ${header.id.includes("password") ? "min-w-[160px]" : ""}
                  ${header.id.includes("status") ? "min-w-[100px]" : ""}
                  ${header.id.includes("country") ? "min-w-[160px]" : ""}
                `}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="relative">
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                className="border-b border-[#333333] hover:bg-[#1A1A1A]"
              >
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`
                    py-4 text-sm text-white
                    ${
                      cell.column.id.includes("proxyAddress")
                        ? "min-w-[160px]"
                        : ""
                    }
                    ${cell.column.id.includes("port") ? "min-w-[100px]" : ""}
                    ${
                      cell.column.id.includes("username") ? "min-w-[160px]" : ""
                    }
                    ${
                      cell.column.id.includes("password") ? "min-w-[160px]" : ""
                    }
                    ${cell.column.id.includes("status") ? "min-w-[100px]" : ""}
                    ${cell.column.id.includes("country") ? "min-w-[160px]" : ""}
                  `}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <>
              <tr className="border-b border-[#333333] hover:bg-[#1A1A1A]">
                <td className="py-4 text-xs text-white pl-[8rem] max-2xl:pl-[3.25rem] max-sm:pl-[1.875rem] min-w-[160px]">
                  105.084.3443
                </td>
                <td className="py-4 text-xs text-white min-w-[100px]">8080</td>
                <td className="py-4 text-xs text-white min-w-[160px]">
                  uwehfier-*****
                </td>
                <td className="py-4 text-xs text-white min-w-[160px]">
                  ********
                </td>
                <td className="py-4 text-xs text-white min-w-[100px]">
                  Checked 2 mins ago
                </td>
                <td className="py-4 text-xs text-white min-w-[160px]">
                  Germany
                </td>
              </tr>
              <div
                style={{
                  background: `linear-gradient(180deg, rgba(31, 30, 30, 0.00) -224.26%, #1F1E1E 42.84%)`,
                }}
                className="h-[8.4375rem] absolute inset-0"
              />
            </>
          )}
        </tbody>
      </table>
    </div>
  );
}
