import React, { useContext, useEffect, useState } from "react";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import CustomButton from "../../../components/CustomButton";
import { DiscordIcon, GoogleIcon } from "../../../constant/Svg";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";

const AuthPage = ({ isLogin = false }) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user, login, register } = useContext(AuthContext);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    try {
      if (isLogin) {
        await login(email, password);
      } else {
        await register(email, password);
      }
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  const handleDiscordLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/discord`;
  };

  return (
    <div className="flex items-center justify-center">
      <div className="w-full max-w-[23.4375rem] max-sm:p-4">
        <h1 className="text-2xl font-bold text-white mb-8 text-center">
          {isLogin ? "Log in" : "Create your account"}
        </h1>

        <form onSubmit={handleSubmit} className="space-y-5">
          <Input
            label="Email or username"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <Input
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            rightElement={
              isLogin && (
                <button
                  type="button"
                  className="text-sm font-bold text-[rgba(220,230,236,0.50)] hover:text-white"
                >
                  Forgot?
                </button>
              )
            }
          />
          {error && <div className="text-red-500 text-sm">{error}</div>}
          <CustomButton
            name={isLogin ? "login" : "create"}
            title={isLogin ? "LOG IN" : "CREATE ACCOUNT"}
            classes="w-full"
            type="submit"
            disabled={loading}
          />

          <div className="relative flex items-center gap-2 my-8">
            <div className="w-full h-[0.0625rem] bg-[rgba(255,255,255,0.10)]" />
            <div className="relative flex justify-center">
              <span className="px-0 text-xs text-white font-bold">OR</span>
            </div>
            <div className="w-full h-[0.0625rem] bg-[rgba(255,255,255,0.10)]" />
          </div>

          <div className="flex gap-[0.875rem]">
            <Button
              type="button"
              name="discord"
              title={
                <div className="flex items-center justify-center gap-2">
                  <DiscordIcon />
                  <span>
                    {isLogin ? "Login with Discord" : "Sign up with Discord"}
                  </span>
                </div>
              }
              classes="w-full"
              onClick={handleDiscordLogin}
            />

            <Button
              type="button"
              name="google"
              title={
                <div className="flex items-center justify-center gap-2">
                  <GoogleIcon />
                  <span>
                    {isLogin ? "Login with Google" : "Sign up with Google"}
                  </span>
                </div>
              }
              classes="w-full"
              onClick={handleGoogleLogin}
            />
          </div>
        </form>

        <p className="mt-8 text-xs text-[rgba(255,255,255,0.50)] font-medium text-center">
          By signing in to Roundproxies, you agree to our{" "}
          <a href="#" className="hover:underline font-bold">
            Terms
          </a>{" "}
          and{" "}
          <a href="#" className="hover:underline font-bold">
            Privacy Policy
          </a>
          .
        </p>

        <p className="mt-4 text-xs text-[rgba(255,255,255,0.50)] text-center px-6">
          This site is protected by reCAPTCHA Enterprise and the Google{" "}
          <a href="#" className="hover:underline font-bold">
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="#" className="hover:underline font-bold">
            Terms of Service
          </a>{" "}
          apply.
        </p>
      </div>
    </div>
  );
};

export default AuthPage;
