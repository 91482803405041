import "tippy.js/dist/tippy.css";
import { SearchIcon } from "../../../constant/Svg";
import TimeRange from "./TimeRange";
import { ProxyType } from "../../../types/proxy";
import ProxyTypeDropdown from "./ProxyTypeDropdown";
import { formatDate } from "../../../utils/datetime";

interface ActivityTabsProps {
  type: ProxyType;
  onTypeChange: (type: ProxyType) => void;
  timeRange: string;
  onTimeRangeChange: (range: string) => void;
  onUpdate: () => void;
  loading: boolean;
}

export default function ActivityTabs({
  type,
  onTypeChange,
  timeRange,
  onTimeRangeChange,
  onUpdate,
  loading,
}: ActivityTabsProps) {
  const proxyTypeOptions = [{ id: ProxyType.DATACENTER, label: "Datacenter" }];

  const timeRanges = [
    { id: "last30", label: "Last 30 Days" },
    { id: "last7", label: "Last 7 Days" },
    { id: "last24", label: "Last 24 Hours" },
  ];

  const getDateRange = () => {
    const endDate = new Date();
    let startDate = new Date();

    switch (timeRange) {
      case "last30":
        startDate.setDate(endDate.getDate() - 30);
        break;
      case "last7":
        startDate.setDate(endDate.getDate() - 7);
        break;
      case "last24":
        startDate.setDate(endDate.getDate() - 1);
        break;
      default:
        startDate.setDate(endDate.getDate() - 30);
    }

    return `${formatDate(startDate)}, 12:00 - ${formatDate(endDate)}`;
  };

  return (
    <div className="flex min-w-0 min-h-[4.8125rem] overflow-x-auto noscroll">
      <div className="px-[6.25rem] max-2xl:px-[3.25rem] max-sm:pl-[1.875rem] py-[1.5625rem]">
        <div className="flex items-center gap-6 pr-4">
          <div className="flex items-center space-x-4">
            <button
              disabled={loading}
              onClick={onUpdate}
              className="!px-4 !py-[0.625rem] !leading-none !min-h-[2.1875rem] !text-xs rounded-[0.3125rem] transition-colors !border-none !text-white !bg-[#191719] hover:!bg-[#2A2A2A] hover:text-white"
            >
              Update
            </button>

            <ProxyTypeDropdown
              activeTab={type}
              proxyTypeOptions={proxyTypeOptions}
              setActiveTab={onTypeChange}
            />

            <div className="h-[2.1875rem] w-[0.1875rem] bg-[rgba(217,217,217,0.10)]" />

            <div className="relative bg-[#191719] flex items-center rounded-[0.1875rem]">
              <TimeRange
                value={timeRange}
                onChange={(e) => onTimeRangeChange(e.target.value)}
                timeRanges={timeRanges}
              />
              <div className="text-xs px-5 text-nowrap">{getDateRange()}</div>
            </div>
          </div>

          <div className="flex items-center space-x-[0.625rem] max-w-64 w-full bg-[#191719] rounded-[0.1875rem] pl-[0.625rem]">
            <SearchIcon />
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Search term..."
                className="bg-transparent text-white py-2 w-full pr-2 min-h-[2.1875rem] min-w-[10.25rem] focus:outline-none text-xs placeholder:text-[rgba(237,237,237,0.50)]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
