import { useContext, useEffect, useState } from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ProxyTable from "./components/ProxyTable";
import ProxyTabs from "./components/ProxyTabs";
import { AuthContext } from "../../context/AuthContext";
import { useApi } from "../../api/api";
import { Proxy, ProxyType } from "../../types/proxy";
import axiosErrorHandler from "../../utils/axiosErrorHandler";

export default function Dashboard() {
  const [userData, setUserData] = useState<any>(null);
  const [proxies, setProxies] = useState<Proxy[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { logout } = useContext(AuthContext);
  const api = useApi();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get("/users/me");
        setUserData(response.data);
      } catch (error: any) {
        console.error("Failed to fetch user data:", error);
        if (error.response && error.response.status === 401) {
          logout();
        }
      }
    };
    fetchUserData();
  }, [api, logout]);

  useEffect(() => {
    const fetchProxies = async () => {
      setIsLoading(true);
      try {
        const response = await api.get("/ip/list");
        setProxies(response.data.proxies);
      } catch (error) {
        axiosErrorHandler(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProxies();
  }, [api]);

  const handleDownloadAll = async () => {
    try {
      setIsLoading(true);
      const proxyString = proxies
        .map((p) => `${p.proxyAddress}:${p.port}:${p.username}:${p.password}`)
        .join("\n");

      const blob = new Blob([proxyString], { type: "text/plain" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `proxies.txt`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download proxies:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyAll = async () => {
    try {
      setIsLoading(true);
      const proxyString = proxies
        .map((p) => `${p.proxyAddress}:${p.port}:${p.username}:${p.password}`)
        .join("\n");
      await navigator.clipboard.writeText(proxyString);
    } catch (error) {
      console.error("Failed to copy proxies:", error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className="h-screen grid grid-rows-[auto_1fr_auto]"
      style={{
        background: `linear-gradient(0deg, rgba(28, 27, 27, 0.50) 0%, rgba(28, 27, 27, 0.50) 100%), #212121`,
      }}
    >
      <Navbar />
      <div className="flex flex-col min-h-0 overflow-auto">
        <ProxyTabs
          isLoading={isLoading}
          onDownloadAll={handleDownloadAll}
          onCopyAll={handleCopyAll}
        />
        <div className="w-full">
          <ProxyTable proxies={proxies} />
        </div>
      </div>
      <Footer />
    </div>
  );
}
