import React, { useState, useRef, useEffect } from "react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { DownArrow } from "../../../constant/Svg";

interface TimeRangeType {
  id: string;
  label: string;
}

interface TimeRangeProps {
  timeRanges: TimeRangeType[];
  value: string;
  onChange: (event: { target: { value: string } }) => void;
}

const TimeRange: React.FC<TimeRangeProps> = ({
  timeRanges,
  value,
  onChange,
}) => {
  const [width, setWidth] = useState<number>(0);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const updateWidth = () => {
      if (buttonRef.current) {
        setWidth(buttonRef.current.offsetWidth);
      }
    };

    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const handleSelect = (rangeId: string) => {
    onChange({ target: { value: rangeId } });
    setIsOpen(false); // Close the tooltip when an option is selected
  };

  const selectedRange = timeRanges.find((range) => range.id === value);

  return (
    <div className="relative" ref={buttonRef}>
      <Tippy
        interactive
        placement="bottom"
        trigger="manual" // Control the tooltip manually
        visible={isOpen} // Set visibility based on state
        onClickOutside={() => setIsOpen(false)}
        offset={[0, 4]}
        animation="fade"
        appendTo={() => document.body}
        arrow={false}
        content={
          <div
            className="bg-[#323131] rounded-[0.1875rem] shadow-lg py-1 z-50"
            style={{ width }}
          >
            {timeRanges.map((range) => (
              <button
                key={range.id}
                onClick={() => handleSelect(range.id)}
                className={`w-full px-4 py-2 text-left transition-colors text-xs ${
                  value === range.id
                    ? "text-white bg-[#272626]"
                    : "text-white hover:bg-[#272626]"
                }`}
              >
                {range.label}
              </button>
            ))}
          </div>
        }
      >
        <button
          onClick={() => setIsOpen((prev) => !prev)} // Toggle tooltip visibility
          className="w-full flex items-center justify-between bg-[#191719] hover:!bg-[#2A2A2A] border-r border-[rgba(255,255,255,0.10)] min-w-[9rem] min-h-[2.1875rem] px-4 py-2 rounded-tl-[0.1875rem] rounded-bl-[0.1875rem] text-white transition-colors"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
        >
          <span className="text-xs tracking-[0.0094rem] mr-4">
            {selectedRange?.label}
          </span>
          <DownArrow />
        </button>
      </Tippy>
    </div>
  );
};

export default TimeRange;
