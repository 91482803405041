import { CanceledError, isAxiosError } from "axios";
import { toast } from "sonner";

export default function axiosErrorHandler(
  error: unknown,
  onUnauthorizedError?: () => void,
  hideAxiosError?: boolean
) {
  console.log(error);
  if (error instanceof Error && !(error instanceof CanceledError)) {
    if (isAxiosError(error)) {
      if (error.response?.status === 401 && !!onUnauthorizedError) {
        onUnauthorizedError?.();
        return;
      }
      if (
        hideAxiosError &&
        error?.response?.data?.message?.toLowerCase().includes("request failed")
      ) {
        console.log(error.message);
        toast.error("Something went wrong");
        return;
      }
      console.log(error?.response?.data);

      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.error ||
          "Something went wrong"
      );
    } else {
      console.log(error.message);
      toast.error(error.message);
    }
  }
}
