import React, { useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import axios from "axios";

const OAuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAccessToken, setRefreshToken, setUser } = useContext(AuthContext);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const accessToken = params.get("accessToken");
    const refreshToken = params.get("refreshToken");

    if (accessToken && refreshToken) {
      setAccessToken(accessToken);
      setRefreshToken(refreshToken);
      const fetchUserData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/users/me`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          setUser(response.data);
          navigate("/dashboard");
        } catch (error) {
          console.error("Failed to fetch user data:", error);
          navigate("/login");
        }
      };

      fetchUserData();
    } else {
      navigate("/login");
    }
  }, [location, navigate, setAccessToken, setRefreshToken]);

  return <div>Authenticating...</div>;
};

export default OAuthCallback;
