import { useState, useEffect } from "react";
import { useApi } from "../../../api/api";
import { PriceSvg } from "../../../constant/Svg";
import axiosErrorHandler from "../../../utils/axiosErrorHandler";
import { UsageOption } from "../../../types/purchase";
import Spinner from "./Spinner";
import { SubscriptionData } from "../Purchase";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

interface CheckoutProps {
  priceId: string;
  quantity: number;
  usage: UsageOption;
  isError: boolean;
  processedRegions: Record<string, number>;
  subscriptionData: SubscriptionData | null;
  selected: "shared" | "dedicated";
  count: string;
  regionDistribution: Record<string, string>;
  usageOption: UsageOption;
  totalPrice: number;
}

export default function Checkout({
  priceId,
  quantity,
  usage,
  isError,
  processedRegions,
  subscriptionData,
  selected,
  count,
  regionDistribution,
  usageOption,
  totalPrice,
}: CheckoutProps) {
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const navigate = useNavigate();
  const api = useApi();

  const buttonText = subscriptionData ? "Modify Subscription" : "Checkout";

  // Disable the button if loading, there's an error, no regions are selected, or no changes have been made
  const isDisabled =
    loading ||
    isError ||
    Object.keys(processedRegions).length === 0 ||
    (subscriptionData && !hasChanges);

  useEffect(() => {
    if (!subscriptionData) {
      setHasChanges(true); // No subscription data means it's a new subscription
      return;
    }

    const isTypeChanged = subscriptionData.type !== selected;
    const isIpCountChanged = subscriptionData.ipCount !== parseInt(count, 10);
    const isUsageOptionChanged =
      subscriptionData.usageOption !==
      (usageOption === UsageOption.UNLIMITED ? "unlimited" : "limited");

    // Compare regions
    const isRegionsChanged =
      JSON.stringify(subscriptionData.regions) !==
      JSON.stringify(processedRegions);

    const changesDetected =
      isTypeChanged ||
      isIpCountChanged ||
      isUsageOptionChanged ||
      isRegionsChanged;

    setHasChanges(changesDetected);
  }, [subscriptionData, selected, count, usageOption, processedRegions]);

  const handleCheckout = async () => {
    if (loading) return;

    if (!quantity || quantity <= 0) {
      console.error("Quantity must be greater than zero");
      return;
    }

    if (Object.keys(processedRegions).length === 0) {
      console.error("Please select at least one region");
      return;
    }

    setLoading(true);
    try {
      const requestBody = {
        type: selected,
        ipCount: parseInt(count, 10),
        usageOption:
          usageOption === UsageOption.UNLIMITED ? "unlimited" : "limited",
        regions: processedRegions,
      };

      if (subscriptionData) {
        // Modify existing subscription
        const subscriptionId = subscriptionData.subscriptionId;
        if (!subscriptionId) {
          throw new Error("Subscription ID is missing");
        }

        const resp = await api.post(
          `/payment/subscriptions/${subscriptionId}`,
          requestBody
        );

        console.log("Subscription modified successfully", resp.data);
        toast.success("Your subscription has been modified successfully");
        navigate("/dashboard");

        // You might want to redirect the user or update the UI accordingly
      } else {
        // Create a new subscription
        const resp = await api.post(
          "/payment/checkout",
          { regions: processedRegions },
          {
            params: {
              priceId,
              quantity,
              usage,
            },
          }
        );

        if (!resp.data || !resp.data.url) {
          throw new Error("Invalid response data");
        }

        window.open(resp.data.url, "_self");
      }
    } catch (error) {
      axiosErrorHandler(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-md:py-[1.125rem] max-md:bg-[#212121] max-md:border-t max-md:border-t-[rgba(255,255,255,0.05)] max-md:fixed max-md:left-0 max-md:bottom-0 max-md:w-full z-20 max-md:px-[1.875rem] max-sm:px-3">
      <div className="relative max-md:hidden">
        <div
          style={{
            textShadow: `rgb(255 255 255 / 5%) 0px 1px 0px, rgb(0 0 0 / 30%) 0px 0px 0px, rgb(0 0 0 / 30%) 0px 0px 0px`,
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
          }}
          className="absolute inset-0 pt-[3.3125rem] h-max leading-[1] flex justify-center"
        >
          <div className="text-[rgba(38,36,36,0.50)] text-[3.4375rem] font-bold font-inter">
            {totalPrice}
          </div>
          <span className="text-[rgba(38,36,36,0.50)] self-start text-[1.25rem] font-bold font-inter">
            €
          </span>
        </div>
        <PriceSvg />
        <div className="absolute bottom-[1.625rem] text-center left-0 right-0">
          <p className="text-[0.625rem] text-[rgba(255,255,255,.5)] leading-4 max-w-[13.4375rem] mx-auto w-full">
            Roundproxies{" "}
            <span className="text-white font-bold">yearly subscription</span>{" "}
            but with a 30% discount{" "}
            <span className="text-white font-bold">2 months for free.</span>
          </p>
        </div>
      </div>
      <button
        disabled={isDisabled || false}
        onClick={handleCheckout}
        className={`flex justify-center items-center min-h-[2.1875rem] px-4 text-center font-[Roboto] text-sm font-bold mb-3 
          ${
            isDisabled
              ? "cursor-not-allowed bg-[rgba(255,255,255,.7)]"
              : "bg-white hover:bg-[#e0e0e0] active:bg-[#c7c7c7]"
          } 
          text-black mt-[1.125rem] rounded-[0.1875rem] w-full transition-colors`}
      >
        {loading ? <Spinner /> : `${buttonText}  - €${totalPrice}`}
      </button>
      <p className="text-[0.625rem] max-w-[85%] max-md:hidden">
        You will receive full plan control, tax-ready documents, and 24/7
        support.
      </p>
    </div>
  );
}
