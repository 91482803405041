import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { User } from "../types/user";

interface AuthContextType {
  isAuthenticated: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  user: any;
  login: (email: string, password: string) => Promise<void>;
  register: (
    email: string,
    password: string,
    // provision for first name and last name if introduced in the future
    firstName?: string,
    lastName?: string
  ) => Promise<void>;
  logout: () => void;
  setUser: (value: React.SetStateAction<User | null>) => void;
  setAccessToken: (value: React.SetStateAction<string | null>) => void;
  setRefreshToken: (value: React.SetStateAction<string | null>) => void;
}

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  accessToken: null,
  refreshToken: null,
  user: null,
  login: async () => {},
  register: async () => {},
  logout: () => {},
  setUser: () => {},
  setAccessToken: () => {},
  setRefreshToken: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem("accessToken")
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem("refreshToken")
  );
  const [user, setUser] = useState<User | null>(
    JSON.parse(localStorage.getItem("user") || "null")
  );
  const navigate = useNavigate();

  const isAuthenticated = !!accessToken;

  useEffect(() => {
    // Save tokens and user data to localStorage
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
    } else {
      localStorage.removeItem("accessToken");
    }
    if (refreshToken) {
      localStorage.setItem("refreshToken", refreshToken);
    } else {
      localStorage.removeItem("refreshToken");
    }
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [accessToken, refreshToken, user]);

  const login = async (email: string, password: string) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/login`,
        {
          email,
          password,
        }
      );
      const { tokens, user: userData } = response.data;
      setAccessToken(tokens.accessToken);
      setRefreshToken(tokens.refreshToken);
      setUser(userData);
      navigate("/dashboard");
    } catch (error: any) {
      throw new Error(error.response?.data?.message || "Login failed");
    }
  };

  const register = async (
    email: string,
    password: string,
    // provision for first name and last name if introduced in the future
    firstName?: string,
    lastName?: string
  ) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/register`,
        {
          email,
          password,
          firstName,
          lastName,
        }
      );
      const { tokens, user: userData } = response.data;
      setAccessToken(tokens.accessToken);
      setRefreshToken(tokens.refreshToken);
      setUser(userData);
      navigate("/dashboard");
    } catch (error: any) {
      throw new Error(error.response?.data?.message || "Registration failed");
    }
  };

  const logout = () => {
    setAccessToken(null);
    setRefreshToken(null);
    setUser(null);
    localStorage.clear();
    navigate("/login");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        accessToken,
        refreshToken,
        setAccessToken,
        setRefreshToken,
        user,
        setUser,
        login,
        register,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
