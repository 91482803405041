import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useContext, useMemo } from "react";

export const useApi = () => {
  const { accessToken, refreshToken, setAccessToken, setRefreshToken, logout } =
    useContext(AuthContext);

  // Create new axios instance for each render
  const api = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    // Request interceptor
    instance.interceptors.request.use(
      (config) => {
        // Get the current accessToken from closure
        if (accessToken) {
          config.headers["Authorization"] = "Bearer " + accessToken;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // Response interceptor
    instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        if (
          error.response.status === 401 &&
          !originalRequest._retry &&
          refreshToken
        ) {
          originalRequest._retry = true;
          try {
            const response = await axios.post(
              `${process.env.REACT_APP_API_URL}/auth/refresh`,
              {
                refreshToken,
              }
            );
            const {
              accessToken: newAccessToken,
              refreshToken: newRefreshToken,
            } = response.data;
            setAccessToken(newAccessToken);
            setRefreshToken(newRefreshToken);
            originalRequest.headers["Authorization"] =
              "Bearer " + newAccessToken;
            return axios(originalRequest);
          } catch (refreshError) {
            logout();
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );

    return instance;
  }, [accessToken, refreshToken, setAccessToken, setRefreshToken, logout]);

  return api;
};
