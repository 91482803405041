import React from "react";

export default function AuthHeader({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <div className="p-6 flex items-end justify-end max-sm:hidden">
      {children}
    </div>
  );
}
