import AuthHeader from "./components/AuthHeader";
import Button from "../../components/Button";
import AuthPage from "./components/AuthPage";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const navigate = useNavigate();

  return (
    <div>
      <AuthHeader>
        <Button title="Login" name="login" onClick={() => navigate("/login")} />
      </AuthHeader>
      <div className="mt-10">
        <AuthPage />
      </div>
      <div className="text-base font-bold text-center mt-[0.375rem] sm:hidden">
        Have an account?{" "}
        <span
          className="text-[#49C0F8] cursor-pointer"
          onClick={() => navigate("/login")}
        >
          Login
        </span>
      </div>
    </div>
  );
}
