import { ReactNode } from "react";

interface RadioOptionProps {
  selected?: boolean;
  label: string;
  description?: ReactNode;
  rightLabel?: string;
  rightDescription?: string;
  badge?: string;
  onClick?: () => void;
}

export const RadioOption = ({
  selected = false,
  label,
  description,
  rightLabel,
  rightDescription,
  badge,
  onClick,
}: RadioOptionProps) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center justify-between w-full p-4 px-6 min-h-[3.75rem] rounded-[0.1875rem] bg-[#272626] cursor-pointer hover:bg-[#323131] transition-all`}
    >
      <div className="flex items-center gap-5 w-full">
        {/* Radio button circle */}
        <div
          className={`min-w-5 h-5 rounded-full border-2 flex items-center justify-center ${
            selected ? "border-[#49C0F8]" : "border-[#9F9F9F]"
          }`}
        >
          {selected && <div className="w-2 h-2 rounded-full bg-[#49C0F8]" />}
        </div>

        {/* Content */}
        <div className="w-full">
          <div className="text-[#FFFFFF] text-xs leading-6 font-bold flex items-center gap-2 justify-between w-full">
            {label}
            {badge && (
              <span className="px-2 py-0.5 text-[0.625rem] leading-[1.125rem] bg-[#49C0F8] text-white rounded-sm">
                {badge}
              </span>
            )}
          </div>
          {description && (
            <div className="text-[#9F9F9F] text-[0.625rem] mt-0.5">
              {description}
            </div>
          )}
        </div>
      </div>

      {/* Right side content */}
      {(rightLabel || rightDescription) && (
        <div className="text-right w-full leading-[1.1875rem]">
          {rightLabel && (
            <div className="text-[#FFFFFF] font-bold text-xs">{rightLabel}</div>
          )}
          {rightDescription && (
            <div className="opacity-50 w-full text-[0.6875rem]">
              {rightDescription}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
