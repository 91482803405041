type InputProps = {
  label: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  rightElement?: React.ReactNode;
};

const Input = ({
  label,
  type = "text",
  value,
  onChange,
  rightElement,
}: InputProps) => (
  <div className="relative mb-4 w-full bg-[#191719] block border rounded-md  border-[rgba(255,255,255,0.05)]">
    <input
      type={type}
      value={value}
      onChange={onChange}
      placeholder={label}
      className="w-full font-medium bg-transparent px-4 py-3 text-sm text-white placeholder-[#DCE6EC] focus:outline-none focus:border-[rgba(255,255,255,0.2)] transition-colors"
      style={{
        width: !!rightElement ? "80%" : "100%",
      }}
    />
    {rightElement && (
      <div className="absolute right-3 top-1/2 -translate-y-1/2">
        {rightElement}
      </div>
    )}
  </div>
);

export default Input;
